import { FC, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getBulkShippingOrderList, getBulkShippingOrder } from '../../Storefront/Shipping/actions';

// Plugins
import moment from 'moment';

// Components
import Header from '../Header';
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';

// Hooks
import { useJobTimeZone } from '@/hooks';

// Helpers
import { normalizePhone } from '@/utils/formatters';

// Styles
import './style.css';

// Types
interface ShippingProps {
  match: {
    params: {
      jobId: string;
    };
  };
}

const Shipping: FC<ShippingProps> = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const jobTimezone = useJobTimeZone();

  const {
    params: { jobId }
  } = match;
  const {
    entities: { bulkShipOrderList, bulkShipOrder },
    requesting
  } = useSelector((state: any) => state.shippings);

  const [showIndividualOrders, setShowIndividualOrders] = useState<boolean>(false);

  const handleRowExpand = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();

    const element = event.currentTarget;

    element.classList.toggle('table-dynamic__section--active');
  };

  const handleIndividualOrdersOpen = (event: React.MouseEvent<HTMLButtonElement>, orderId: string): void => {
    event.stopPropagation();

    dispatch(getBulkShippingOrder({ orderId }));
    setShowIndividualOrders(true);
  };

  const handleIndividualOrdersClose = (): void => {
    setShowIndividualOrders(false);
  };

  useEffect(() => {
    dispatch(getBulkShippingOrderList({ jobId }));
  }, []);

  return (
    <>
      <Header {...{ history, jobId, title: 'Shipping' }} />

      <main className="box">
        <header className="flex middle job-shipping__header">
          <h2 className="text--nomargin">Bulk Shipment History</h2>
        </header>

        <article className="table-dynamic">
          {/* Header */}
          <ul className="table-dynamic__header">
            <li className="table-dynamic__cell">Deadline Date</li>
            <li className="table-dynamic__cell">Ship Date</li>
            <li className="table-dynamic__cell">Tracking</li>
            <li className="table-dynamic__cell">Address</li>
            <li className="table-dynamic__cell">Delivery Note</li>
            <li className="table-dynamic__cell">Bulk Order Number</li>
            <li className="table-dynamic__cell">Individual Orders</li>
          </ul>

          {/* Table */}
          {requesting ? (
            <TableLoader rows={8} rowHeight={75} />
          ) : (
            <>
              {bulkShipOrderList.map((data: any) => (
                <div key={data.id} className="table-dynamic__section" onClick={handleRowExpand}>
                  <ul className="table-dynamic__row">
                    <li data-header="Deadline Date" className="table-dynamic__cell">
                      {moment(data.expires_at).format('L')}
                    </li>
                    <li data-header="Ship Date" className="table-dynamic__cell">
                      {moment(data.shipped_at).format('L')}
                    </li>
                    <li data-header="Tracking" className="table-dynamic__cell">
                      {data.tracking_number ? (
                        <a href={data.tracking_url} target="_blank" rel="noopener noreferrer" onClick={(e) => e.stopPropagation()}>
                          {data.tracking_number}
                        </a>
                      ) : (
                        'N/P'
                      )}
                    </li>
                    <li data-header="Address" className="table-dynamic__cell text--capitalize">
                      {data.address.line1}, {data.address.line2}
                      <br />
                      {data.address.city}, {data.address.state} {data.address.zip}
                    </li>
                    <li data-header="Delivery Note" className="table-dynamic__cell table-dynamic__cell--maxheight">
                      {data.notes}
                    </li>
                    <li data-header="Bulk Order Number" className="table-dynamic__cell">
                      {data.num ?? 'N/P'}
                    </li>
                    <li data-header="Individual Orders" className="table-dynamic__cell">
                      {data.orders_count > 0 ? (
                        <button className="button button--link" onClick={(event) => handleIndividualOrdersOpen(event, data.id)}>
                          Order List
                        </button>
                      ) : (
                        <span>N/P</span>
                      )}
                    </li>
                  </ul>
                  {/* Sub-data row */}
                  <ul className="table-dynamic__row">
                    <li data-header="Order Quantity" className="table-dynamic__cell">
                      <h5 className="hidden--sm">Order Quantity</h5>
                      {data.orders_count}
                    </li>
                    <li data-header="% of Total Orders" className="table-dynamic__cell table-dynamic__cell--separator">
                      <h5 className="hidden--sm">% of Total Orders</h5>
                      {data.orders_count_percent}%
                    </li>
                    <li data-header="Bulk Total" className="table-dynamic__cell">
                      <h5 className="hidden--sm">Bulk Total</h5>
                      {Number(data.orders_total_cents / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                    </li>
                    <li data-header="% of Total Order Sales" className={`table-dynamic__cell ${data.fee_charged ? 'table-dynamic__cell--separator' : ''}`}>
                      <h5 className="hidden--sm">% of Total Order Sales</h5>
                      {data.orders_total_percent}%
                    </li>
                    {data.fee_charged && (
                      <li data-header="Lab Charges" className="table-dynamic__cell">
                        <h5 className="hidden--sm">Lab Charges</h5>
                        This order was charged the lab minimum $15
                      </li>
                    )}
                  </ul>
                </div>
              ))}
            </>
          )}
        </article>
      </main>

      {/* Individual Orders Modal */}
      {showIndividualOrders && (
        <aside className="modal animate">
          <div className="modal__box job-shipping__orders">
            <header className="modal__header">
              <button className="button button--action modal__close" name="close" type="button" onClick={handleIndividualOrdersClose}>
                <i className="icon-close"></i>
              </button>
              <h3 className="text--left">Individual Orders</h3>
            </header>
            <main className="modal__content">
              {requesting ? (
                <TableLoader rows={3} rowHeight={40} />
              ) : (
                <table className="table">
                  <thead className="table__header">
                    <tr>
                      <th>Purchase Date</th>
                      <th>Customer Info</th>
                      <th>Subject Name(s)</th>
                      <th>Order Number</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody className="table__body">
                    {bulkShipOrder.map((order: any) => (
                      <tr key={order.id}>
                        <td data-header="Purchase Date">
                          {order.submitted_at && jobTimezone ? moment(order.submitted_at)?.tz(jobTimezone)?.format('L') : 'N/A'}
                        </td>
                        <td data-header="Customer Info">
                          Name: {order.customer_name}
                          <br />
                          Email: {order.email}
                          <br />
                          Phone #: {normalizePhone(order.phone)}
                        </td>
                        <td data-header="Subject Name(s)">{order.subject_names.join(', ')}</td>
                        <td data-header="Order Number">
                          <Link to={`/storefront/orders/${order.id}`}>{order.num}</Link>
                        </td>
                        <td data-header="Amount">{Number(order.total_cents / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </main>
          </div>
        </aside>
      )}
    </>
  );
};

export default Shipping;
