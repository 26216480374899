import { FC, useState, useEffect, useCallback, useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

// Plugins
import moment from 'moment';
import { debounce } from 'lodash';
import { endOfMonth } from 'date-fns';
import { Tooltip } from 'react-tippy';
import queryString from 'query-string';
import DatePicker from 'react-datepicker';
import Select, { MultiValue, OnChangeValue } from 'react-select';

// Hooks
import { useStudioTimeZone } from '@/hooks';

// Helpers
import { normalizePhone } from '@/utils/formatters';
import { shortDate, pickerDate } from '@/utils/displayFormats';

// Components
import Header from '../Header';
import Dropdown from '@/components/Shared/Dropdown';
import Pagination from '@/components/Shared/Pagination';
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';

// Redux
import { getJobList } from '../../Jobs/actions';
import { useSelector, useDispatch } from 'react-redux';
import { salesMonthReportRequest, salesMonthReportDownloadRequest } from '../actions';

// Types
import { Job, Order, TableFilter, SelectOptionType } from '@/types';

type LocationState = {
  month?: number;
  year?: number;
  ordersFilter?: SelectOptionType[];
  searchFilter?: string;
  startDate?: Date;
  endDate?: Date;
  page?: number;
  per_page?: number;
  order?: TableFilter['order'];
  dir?: TableFilter['dir'];
};

type Location = { pathname: string; search: string; state: LocationState | null | undefined };

interface OrdersProps {
  match: { path: string };
  history: { location: Location; push: (location: string | { search: string }) => void };
}

// Constants
const ORDER_PAGE: number = 1;
const ORDER_PER_PAGE: number = 20;

const Orders: FC<OrdersProps> = ({ match }) => {
  const history = useHistory();
  const location: Location = useLocation();
  const locationState = location.state;
  const dispatch = useDispatch();
  const studioTimeZone = useStudioTimeZone();

  const { user, studio } = useSelector((state: any) => state.login);
  const {
    monthReport: { list: monthReportList, pagination, requesting: monthReportRequesting }
  } = useSelector((state: any) => state.reports);

  const {
    jobs: { list },
    requesting: jobsRequesting
  } = useSelector((state: any) => state.jobs);
  const { page: queryPage } = queryString.parse(location.search);
  const requesting = monthReportRequesting || jobsRequesting;

  const didCheckLocationStateRef = useRef(false);

  const initialTableFilter: TableFilter = {
    order: 'date',
    dir: 'DESC',
    search: '',
    page: Number(queryPage) || ORDER_PAGE,
    per_page: ORDER_PER_PAGE
  };

  const [startDate, setStartDate] = useState<Date | null | undefined>(null);
  const [endDate, setEndDate] = useState<Date | null | undefined>(null);
  const [didCheckLocationState, setDidCheckLocationState] = useState<boolean>(false);

  const [selectJobs, setSelectJobs] = useState<MultiValue<SelectOptionType>>([]);
  const [search, setSearch] = useState<string>('');
  const [tableFilter, setTableFilter] = useState<TableFilter>({});

  const setQueryPage = (page: number) => history.push({ search: `?page=${page}` });

  const setStateFromLocationState = (state: Location['state']) => {
    if (!state || state === null) return;

    const {
      dir: locationDir,
      page: locationPage,
      year: locationYear,
      order: locationOrder,
      month: locationMonth,
      endDate: locationEnd,
      startDate: locationStart,
      ordersFilter: locationOrdersFilter,
      searchFilter: locationSearchFilter
    } = state;
    const requestPayload: any = {};
    const requestFilter: TableFilter = { ...tableFilter, per_page: ORDER_PER_PAGE };

    if (locationSearchFilter) {
      setSearch(locationSearchFilter);

      requestFilter.search = locationSearchFilter;
    } else {
      const filterStart = locationStart ? locationStart : locationYear && locationMonth ? new Date(locationYear, locationMonth - 1, 1) : null;
      const filterEnd = locationEnd ? locationEnd : filterStart ? endOfMonth(filterStart) : null;
      const start_date = moment(filterStart).isValid() ? pickerDate(filterStart) : null;
      const end_date = moment(filterEnd).isValid() ? pickerDate(filterEnd) : null;

      setEndDate(filterEnd);
      setStartDate(filterStart);

      requestPayload.start_date = start_date;
      requestPayload.end_date = end_date;

      if (locationOrdersFilter && locationOrdersFilter.length > 0) {
        setSelectJobs(locationOrdersFilter);
        requestPayload.jobs = locationOrdersFilter.map((item) => item.value);
      }
    }

    if (locationOrder) requestFilter.order = locationOrder;
    if (locationDir) requestFilter.dir = locationDir;
    if (locationPage) {
      setQueryPage(locationPage);
      requestFilter.page = locationPage;
    }

    dispatch(
      salesMonthReportRequest({ ...requestPayload, ...requestFilter }, () => {
        setTableFilter({ ...tableFilter, ...requestFilter });
        didCheckLocationStateRef.current = true;
      })
    );
  };

  const getOrdersList = useCallback(
    debounce(
      ({ filterByJobs = false, search }: { filterByJobs?: boolean; search?: string } = {}) => {
        if (search) {
          dispatch(salesMonthReportRequest({ search, ...tableFilter, page: ORDER_PAGE, per_page: ORDER_PER_PAGE }));
        } else {
          const start_date = moment(startDate).isValid() ? pickerDate(startDate) : null;
          const end_date = moment(endDate).isValid() ? pickerDate(endDate) : null;
          const jobs = selectJobs && selectJobs.length ? selectJobs.map((item) => item.value) : [];

          // api will default to current YTD if no start or end date provided.
          dispatch(
            salesMonthReportRequest({
              jobs,
              ...tableFilter,
              ...(end_date && { end_date }),
              ...(start_date && { start_date }),
              ...(filterByJobs && { search: '', page: ORDER_PAGE, per_page: ORDER_PER_PAGE })
            })
          );
        }
      },
      400,
      { leading: false, trailing: true }
    ),
    [startDate, endDate, selectJobs, tableFilter]
  );

  const handleStartDateChange = (date: Date) => setStartDate(date);
  const handleEndDateChange = (date: Date) => setEndDate(date);
  const handleJobsChange = (select: OnChangeValue<SelectOptionType, true>) => setSelectJobs(select);

  const handleFilter = () => {
    setSearch('');
    setTableFilter({ ...tableFilter, search: '', page: ORDER_PAGE, per_page: ORDER_PER_PAGE });
    getOrdersList({ filterByJobs: true, search: '' });
  };

  const handleOrderBy = (order: string, dir: TableFilter['dir']) => setTableFilter({ ...tableFilter, order, dir, page: ORDER_PAGE, per_page: ORDER_PER_PAGE });

  const handlePagination = (page: number) => {
    setQueryPage(page);
    setTableFilter({ ...tableFilter, page });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => setSearch(e.target.value);

  const handleSearch = (e: React.ChangeEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const trimSearch = search.trim();
    getOrdersList({ search: trimSearch ?? '' });

    // Reset filter options
    setStartDate(null);
    setEndDate(null);
    setSelectJobs([]);
  };

  const handleSearchClear = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();

    setSearch('');
    setTableFilter(initialTableFilter);
  };

  const handleReportExport = () => {
    const start_date = moment(startDate).isValid() ? pickerDate(startDate) : null;
    const end_date = moment(endDate).isValid() ? pickerDate(endDate) : null;

    dispatch(
      salesMonthReportDownloadRequest({
        start_date,
        end_date,
        jobs: selectJobs && selectJobs.length ? selectJobs.map((item) => item.value) : [],
        format: 'csv',
        fileName: `${studio.name} - ${start_date ? start_date : ''} - ${end_date ? `${end_date} - ` : ''}Orders.csv`
      })
    );
  };

  const handleRowExpand = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();

    const element = e.currentTarget;

    element.classList.toggle('table-dynamic__section--active');
    element.classList.toggle('reports-table-dynamic__section--active');
  };

  useEffect(() => {
    if (didCheckLocationState) {
      getOrdersList();
    } else if (didCheckLocationStateRef.current) {
      // Prevent duplicate request when updating tableFilter from setStateFromLocationState
      setDidCheckLocationState(true);
    }
  }, [tableFilter.page, tableFilter.order, tableFilter.dir]);

  useEffect(() => {
    dispatch(getJobList({ order: 'name', dir: 'ASC', per_page: 10000 }));

    // Bypass setStateFromLocationState function if location state is NULL
    if (!locationState || locationState === null) {
      // Populate start date for orders with the beginning of the year by default
      const beginningOfYear = moment().startOf('year');
      const currentDate = moment();

      getOrdersList();
      setDidCheckLocationState(true);
      setEndDate(currentDate.toDate());
      setStartDate(beginningOfYear.toDate());
      setTableFilter({ ...tableFilter, page: ORDER_PAGE, per_page: ORDER_PER_PAGE });
    } else {
      setStateFromLocationState(locationState);
    }
  }, []);

  return (
    <section className="reports">
      <Header match={match} user={user} />

      <main className="box">
        <aside className="flex between hidden--sm mb-10">
          <div className="flex nowrap middle button-group">
            <DatePicker
              className="input--date"
              selected={startDate}
              onChange={handleStartDateChange}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              isClearable={true}
              placeholderText="Start date"
              strictParsing
            />
            <i className="icon-arrow-long-right"></i>
            <DatePicker
              className="input--date"
              selected={endDate}
              onChange={handleEndDateChange}
              selectsEnd
              endDate={endDate}
              minDate={startDate}
              maxDate={new Date()}
              isClearable={true}
              placeholderText="End date"
              strictParsing
            />
            <Select
              className="flex-5 select"
              classNamePrefix="select"
              isMulti={true}
              value={selectJobs}
              isClearable={true}
              placeholder="All Jobs"
              isLoading={requesting}
              options={list && list.length && list.map((job: Job) => ({ value: job.id, label: job.name }))}
              onChange={handleJobsChange}
            />
            <button className="button" name="filter" type="button" onClick={handleFilter} disabled={requesting}>
              Filter
            </button>
          </div>
          <div className="flex end start-md button-group">
            <form onSubmit={handleSearch}>
              <fieldset className="fieldset--clean">
                <input type="search" name="search" placeholder="Search" maxLength={50} value={search} onChange={handleSearchChange} />
                <button
                  className={`button button--clear button--small ${tableFilter.search ? '' : 'hidden'}`}
                  name="button"
                  type="button"
                  onClick={handleSearchClear}
                >
                  Clear
                </button>
                <button className="button button--icon" name="button" type="submit">
                  <i className="icon-search" />
                </button>
              </fieldset>
            </form>
            <Tooltip {...{ title: 'Download CSV', position: 'top', arrow: true }}>
              <button
                className="button button--outline button--small"
                name="export"
                type="button"
                onClick={handleReportExport}
                disabled={monthReportList.length === 0 || requesting}
              >
                <i className="icon-download-sharp"></i>
              </button>
            </Tooltip>
          </div>
        </aside>

        {monthReportList.length > 0 ? (
          <article className="table-dynamic table-dynamic--compact table-dynamic--secondary">
            {/* Header */}
            <ul className="table-dynamic__header">
              <li className="table-dynamic__cell">
                <Dropdown {...{ buttonName: 'Date', buttonExtraClass: 'button--filter' }}>
                  <ul className="panel panel-dropdown panel-dropdown--small panel-dropdown--left">
                    <li
                      className={`panel-dropdown__item ${tableFilter.order === 'date' && tableFilter.dir === 'ASC' ? 'panel-dropdown__item--active' : ''}`}
                      onClick={() => handleOrderBy('date', 'ASC')}
                    >
                      Ascending
                    </li>
                    <li
                      className={`panel-dropdown__item ${tableFilter.order === 'date' && tableFilter.dir === 'DESC' ? 'panel-dropdown__item--active' : ''}`}
                      onClick={() => handleOrderBy('date', 'DESC')}
                    >
                      Descending
                    </li>
                  </ul>
                </Dropdown>
              </li>
              <li className="table-dynamic__cell table-dynamic__cell--large">Order #</li>
              <li className="table-dynamic__cell table-dynamic__cell--xlarge">Customer Info</li>
              <li className="table-dynamic__cell table-dynamic__cell--large">Job Name</li>
              <li className="table-dynamic__cell">
                <Dropdown {...{ buttonName: 'Type', buttonExtraClass: 'button--filter' }}>
                  <ul className="panel panel-dropdown panel-dropdown--small">
                    <li
                      className={`panel-dropdown__item ${tableFilter.order === 'type' && tableFilter.dir === 'ASC' ? 'panel-dropdown__item--active' : ''}`}
                      onClick={() => handleOrderBy('type', 'ASC')}
                    >
                      Ascending
                    </li>
                    <li
                      className={`panel-dropdown__item ${tableFilter.order === 'type' && tableFilter.dir === 'DESC' ? 'panel-dropdown__item--active' : ''}`}
                      onClick={() => handleOrderBy('type', 'DESC')}
                    >
                      Descending
                    </li>
                  </ul>
                </Dropdown>
              </li>
              <li className="table-dynamic__cell">
                <Dropdown {...{ buttonName: 'Status', buttonExtraClass: 'button--filter' }}>
                  <ul className="panel panel-dropdown panel-dropdown--small">
                    <li
                      className={`panel-dropdown__item ${tableFilter.order === 'status' && tableFilter.dir === 'ASC' ? 'panel-dropdown__item--active' : ''}`}
                      onClick={() => handleOrderBy('status', 'ASC')}
                    >
                      Ascending
                    </li>
                    <li
                      className={`panel-dropdown__item ${tableFilter.order === 'status' && tableFilter.dir === 'DESC' ? 'panel-dropdown__item--active' : ''}`}
                      onClick={() => handleOrderBy('status', 'DESC')}
                    >
                      Descending
                    </li>
                  </ul>
                </Dropdown>
              </li>
              <li className="table-dynamic__cell">Tracking #</li>
              <li className="table-dynamic__cell">Subtotal</li>
              <li className="table-dynamic__cell">Disc. Applied</li>
              <li className="table-dynamic__cell">Cred. Used</li>
              <li className="table-dynamic__cell">Total</li>
              <li className="table-dynamic__cell">Shipping</li>
              <li className="table-dynamic__cell">
                <Dropdown {...{ buttonName: 'Gross Amount', buttonExtraClass: 'button--filter' }}>
                  <ul className="panel panel-dropdown panel-dropdown--small">
                    <li
                      className={`panel-dropdown__item ${tableFilter.order === 'amount' && tableFilter.dir === 'ASC' ? 'panel-dropdown__item--active' : ''}`}
                      onClick={() => handleOrderBy('amount', 'ASC')}
                    >
                      Ascending
                    </li>
                    <li
                      className={`panel-dropdown__item ${tableFilter.order === 'amount' && tableFilter.dir === 'DESC' ? 'panel-dropdown__item--active' : ''}`}
                      onClick={() => handleOrderBy('amount', 'DESC')}
                    >
                      Descending
                    </li>
                  </ul>
                </Dropdown>
              </li>
            </ul>

            {/* Table */}
            {requesting ? (
              <TableLoader rows={12} rowHeight={75} />
            ) : (
              monthReportList.map((data: Order, index: number) => (
                <div
                  className={`table-dynamic__section reports-table-dynamic__section ${!data.is_new_tax_system ? 'table-dynamic__section--notexpandable' : ''}`}
                  key={`${index}${data.id}`}
                  onClick={(e) => (data.is_new_tax_system ? handleRowExpand(e) : null)}
                >
                  <ul className="table-dynamic__row">
                    <li className="table-dynamic__cell">
                      {data.submitted_at && studioTimeZone ? `${moment(data.submitted_at)?.tz(studioTimeZone)?.format('L')}` : 'N/A'}
                    </li>
                    <li data-header="Order #" className="table-dynamic__cell table-dynamic__cell--large">
                      {data.type === 'Advance Pay' ? (
                        <Link
                          className="text--normalized"
                          to={{
                            pathname: `/storefront/orders/advance-pay/credits/${data.id}`,
                            state: {
                              endDate,
                              startDate,
                              page: queryPage,
                              dir: tableFilter.dir,
                              searchFilter: search,
                              ordersFilter: selectJobs,
                              order: tableFilter.order,
                              fromReports: location.pathname
                            }
                          }}
                        >
                          <b>{data.num}</b>
                        </Link>
                      ) : (
                        <Link
                          className="text--normalized"
                          to={{
                            pathname: `/storefront/orders/${data.id}`,
                            state: {
                              endDate,
                              startDate,
                              page: queryPage,
                              dir: tableFilter.dir,
                              searchFilter: search,
                              ordersFilter: selectJobs,
                              order: tableFilter.order,
                              fromReports: location.pathname
                            }
                          }}
                        >
                          <b>{data.num}</b>
                        </Link>
                      )}
                    </li>
                    <li
                      data-header="Customer Info"
                      className="table-dynamic__cell table-dynamic__cell--xlarge table-dynamic__cell--tall table-dynamic__cell--align-top"
                    >
                      <div>
                        {data.customer_name && (
                          <>
                            {data.customer_name}
                            <br />
                          </>
                        )}
                        <small>
                          {data.customer_email}
                          <br />
                          {normalizePhone(data.customer_phone)}
                        </small>
                      </div>
                    </li>
                    <li data-header="Job Name" className="table-dynamic__cell table-dynamic__cell--large text--break">
                      {data.job_name}
                    </li>
                    <li data-header="Type" className="table-dynamic__cell">
                      {data.type === 'GiftCard' ? 'Advance Pay' : data.type}
                    </li>
                    <li data-header="Status" className="table-dynamic__cell text--capitalize">
                      {{ paid: 'Paid', fulfilled: `Fulfilled${data.bulk_shipped_at ? '- Bulk' : ''}` }[data.status] || ''}
                    </li>
                    <li data-header="Tracking #" className="table-dynamic__cell text--truncate">
                      {data.bulk_shipped_at ? (
                        <Link className="text--normalized" to={`/jobs/${data.job_id}/shipping`}>
                          Shipped - {shortDate(data.bulk_shipped_at)}
                        </Link>
                      ) : (
                        <>
                          {data.tracking_url ? (
                            <a className="text--truncate" href={data.tracking_url} target="_blank" rel="noopener noreferrer">
                              {data.tracking_number}
                            </a>
                          ) : (
                            data.tracking_number || <i>N/P</i>
                          )}
                        </>
                      )}
                    </li>
                    <li data-header="Subtotal" className="table-dynamic__cell">
                      {data.subtotal ? Number(data.subtotal / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-'}
                    </li>
                    <li data-header="Disc. Applied" className="table-dynamic__cell">
                      {data.discount_applied ? Number(data.discount_applied / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-'}
                    </li>
                    <li data-header="Cred. Used" className="table-dynamic__cell">
                      {data.used_credits ? Number(data.used_credits / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-'}
                    </li>
                    <li data-header="Total" className="table-dynamic__cell">
                      {data.studio_totals ? Number(data.studio_totals / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-'}
                    </li>
                    <li data-header="Shipping" className="table-dynamic__cell">
                      {data.shipping_costs ? Number(data.shipping_costs / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-'}
                    </li>
                    <li data-header="Gross Amount" className="table-dynamic__cell">
                      {data.gross_amount ? Number(data.gross_amount / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-'}
                    </li>
                  </ul>
                  <ul className="table-dynamic__row">
                    <li data-header="Lab Costs" className="table-dynamic__cell">
                      <h5 className="hidden--sm text--nowrap">Lab Costs</h5>
                      {data.lab_total_costs ? Number(data.lab_total_costs / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-'}
                    </li>
                    <li data-header="Retouch" className="table-dynamic__cell">
                      <h5 className="hidden--sm text--nowrap">Retouch Costs</h5>
                      {data.retouch_costs ? Number(data.retouch_costs / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-'}
                    </li>
                    <li data-header="PD Fee" className="table-dynamic__cell">
                      <h5 className="hidden--sm text--nowrap">PD Fee</h5>
                      {data.pd_fee ? Number(data.pd_fee / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-'}
                    </li>
                    <li data-header="CC Fee" className="table-dynamic__cell table-dynamic__cell--separator">
                      <h5 className="hidden--sm text--nowrap">CC Fee</h5>
                      {data.processing_fee ? Number(data.processing_fee / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-'}
                    </li>

                    {data.type === 'Advance Pay' && (
                      <>
                        <li data-header="AP Expire" className="table-dynamic__cell">
                          <h5 className="hidden--sm text--nowrap">AP Expire</h5>
                          {data.ap_expire ? shortDate(data.ap_expire) : '-'}
                        </li>
                        <li data-header="AP Balance" className="table-dynamic__cell table-dynamic__cell--separator">
                          <h5 className="hidden--sm text--nowrap">AP Balance</h5>
                          {data.ap_balance ? Number(data.ap_balance / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-'}
                        </li>
                      </>
                    )}

                    <li data-header="Zip" className="table-dynamic__cell">
                      <h5 className="hidden--sm text--nowrap">Zip</h5>
                      {data.zip || '-'}
                    </li>
                    <li data-header="Sales Tax" className="table-dynamic__cell">
                      <h5 className="hidden--sm text--nowrap">Sales Tax</h5>
                      {data.tax_collectable ? (
                        <span>
                          ${(data.tax_collectable / 100).toFixed(2)}{' '}
                          <i className="table-dynamic__percentage">({Number.parseFloat((data.tax_rate * 100.0).toFixed(8))}%)</i>
                        </span>
                      ) : (
                        <span>-</span>
                      )}
                    </li>
                    <li data-header="State Delivery Fee" className="table-dynamic__cell table-dynamic__cell--separator">
                      <h5 className="hidden--sm text--nowrap">State Delivery Fee</h5>
                      {data.retail_delivery_fees
                        ? Number(data.retail_delivery_fees / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                        : '-'}
                    </li>
                    <li data-header="Studio Payout" className="table-dynamic__cell">
                      <h5 className="hidden--sm text--nowrap">Studio Payout</h5>
                      {data.studio_payout ? Number(data.studio_payout / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-'}
                    </li>
                  </ul>
                </div>
              ))
            )}
          </article>
        ) : (
          <aside className="flex column middle center panel panel--secondary panel--tall">
            <h2>To view orders, select a start and end date or search for one.</h2>
            <p>You can download the data shown by selecting the export option.</p>
          </aside>
        )}
        {pagination.total > pagination.perPage && <Pagination pagination={pagination} onPagination={handlePagination} showPagesCount={4} />}
      </main>
    </section>
  );
};

export default Orders;
