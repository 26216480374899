import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useState, useEffect, memo } from 'react';

// Plugins
import Select from 'react-select';
import moment from 'moment-timezone';
import { Tooltip } from 'react-tippy';
import DatePicker from 'react-datepicker';
import { isEmpty, omitBy, isNil } from 'lodash';

// Helpers
import { useJobTimeZone } from '@/hooks';
import { shortDate } from '@/utils/displayFormats';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getJob, resetJob, updateJob, getJobTimezoneList, getJobSmsTimeList } from '../actions';

// Components
import Dropdown from '@/components/Shared/Dropdown';
import Navigation from '@/components/Shared/Navigation';
import BusinessVerificationBanner from '@/components/Shared/BusinessVerification/VerificationBanner';
import StripeExpressMigrationBanner from '@/components/Shared/StripeExpressMigration/MigrationBanner';

// Images
import imageCart from '@/assets/images/icon-card.png';
import imageStore from '@/assets/images/icon-store.png';
import imageAdvPay from '@/assets/images/image-adv-pay.png';
import imageWarning from '@/assets/images/icon-important.png';
import imageStoreTime from '@/assets/images/icon-store-time.png';
import imageStoreDraft from '@/assets/images/icon-store-draft.png';
import imageGalleryDevices from '@/assets/images/image-gallery-devices.png';

// Styles
import './style.css';

const SERVICES_ENABLED = import.meta.env.VITE_SERVICES_ENABLED === '1';
const ALLOW_EMAIL_CAMPAIGNS = import.meta.env.VITE_ALLOW_EMAIL_CAMPAIGNS === '1';
const BULK_SHIPPING_SUMMARY_PAGE_ENABLED = import.meta.env.VITE_BULK_SHIPPING_SUMMARY_PAGE_ENABLED === '1';

const Header = memo(function Header({ history, jobId, title }) {
  const dispatch = useDispatch();
  const jobTimezone = useJobTimeZone();

  const {
    studio: { id: studioId },
    user: { is_photoday: isPhotodayUser, roles: userRoles }
  } = useSelector((state) => state.login);

  const { job, photos, timezones, smsTimes, errors, requesting } = useSelector((state) => state.jobs);

  const [showJobHistory, setShowJobHistory] = useState(false);
  const [navigationIsOpen, setNavigationIsOpen] = useState(false);

  const [showAdvPayConfirm, setShowAdvPayConfirm] = useState(false);
  const [showPublishConfirm, setShowPublishConfirm] = useState(false);
  const [showBulkDropConfirm, setShowBulkDropConfirm] = useState(false);
  const [showLookupWarningModal, setShowLookupWarningModal] = useState(false);

  const [mobileStatusIsOpen, setMobileStatusIsOpen] = useState(false);
  const [showPublishLaterModal, setShowPublishLaterModal] = useState(false);
  const [publishLaterInfo, setPublishLaterInfo] = useState({ date: null, time: null });

  const hasEmptyInfoBulkShip = (bulkShip) => {
    const { expires_at, recipient, address } = bulkShip;
    const values = { expires_at, recipient, ...address };

    return isEmpty(omitBy(values, isNil));
  };

  const items = [
    { name: 'Dashboard', link: `/jobs/${jobId}/dashboard`, icon: 'icon-dashboard' },
    { name: 'Photos', link: `/jobs/${jobId}/gallery`, icon: 'icon-gallery', disabled: job.access_mode ? false : true },
    { name: 'Marketing', link: `/jobs/${jobId}/marketing`, icon: 'icon-marketing', disabled: job.access_mode ? false : true },
    {
      name: 'Insights',
      link: `/jobs/${jobId}/insights`,
      icon: 'icon-insights',
      hidden: userRoles.includes('view_insights') ? false : true,
      disabled: job.access_mode ? false : true
    },
    {
      name: 'Services',
      link: `/jobs/${jobId}/services`,
      icon: 'icon-export',
      hidden: SERVICES_ENABLED === false || studioId === 'all-studios'
    },
    {
      name: 'Shipping',
      link: `/jobs/${jobId}/shipping`,
      icon: 'icon-shipping',
      hidden: BULK_SHIPPING_SUMMARY_PAGE_ENABLED === false || job.has_bulk_shipment === false
    },
    { name: 'Settings', link: `/jobs/${jobId}/settings`, icon: 'icon-settings' },
    { name: 'Admin', link: `/jobs/${jobId}/admin`, icon: 'icon-settings', hidden: !isPhotodayUser }
  ];

  const currentItem = items.find((item) => title.includes(item.name.toLowerCase())) || items[0];

  const handleJobStatusSelect = (status) => {
    setMobileStatusIsOpen(false);

    // First checks and redirects
    // Check if job type access is not set yet
    if (job.access_mode === null) {
      return history?.push(`/jobs/${job.id}/settings/access`);
    }

    // Check if job is AdvancePay and is missing AP credit sheet
    if (status === 'presale' && !job.gift_card_sheet_id) {
      return history?.push(`/jobs/${job.id}/settings/store/advancepay`);
    }

    // Check if user is trying to publish job without pricesheet
    if ((status === 'onsale' || status === 'scheduled') && !job.price_sheet_id) {
      return history?.push(`/jobs/${job.id}/settings/store/products`);
    }

    // Check if private job is missing subjects
    if (job.access_mode === 'access_per_subject' && job.setup_steps?.uploaded_subjects === false) {
      return history?.push(`/jobs/${job.id}/people`);
    }

    // Check if user is trying to publish job without incompleted steps
    if (
      status !== 'draft' &&
      status !== 'presale' &&
      job.setup_steps?.gallery_access === true &&
      job.setup_steps?.storefront === true &&
      job.setup_steps?.uploaded_photos === false &&
      photos.list.length === 0
    ) {
      return history?.push(`/jobs/${job.id}/gallery`);
    }

    // Open appropriate modal according to user selection
    // Check if first time publishing this job
    if (status === 'onsale' && !job.last_published_at) {
      setShowPublishConfirm(true);
      // Check if there is missing info in bulk shipment
    } else if (status === 'onsale' && (bulkExpired === true || bulkMissingInfo === true)) {
      setShowBulkDropConfirm(true);
    } else if (status === 'scheduled') {
      setShowPublishLaterModal(true);
    } else if (status === 'presale') {
      setShowAdvPayConfirm(true);
    } else {
      dispatch(updateJob({ id: jobId, job_status: status, schedule_publish_at_date: null, schedule_publish_at_time: null }));
    }
  };

  const handleToggleNavigation = () => {
    setNavigationIsOpen(!navigationIsOpen);
  };

  const handlePublishConfirmToggle = () => setShowPublishConfirm(!showPublishConfirm);

  const handleBulkDropConfirmToggle = () => setShowBulkDropConfirm(!showBulkDropConfirm);

  const handleAdvPayConfirmToggle = () => setShowAdvPayConfirm(!showAdvPayConfirm);

  const handlePublishConfirm = () => {
    const validExpireDate = job.expires_at && moment(job.expires_at).isSameOrAfter(new Date(), 'day');

    dispatch(
      updateJob({ id: jobId, job_status: 'onsale', expires_at: validExpireDate ? job.expires_at : null }, () => {
        setShowBulkDropConfirm(false);
        setShowPublishConfirm(false);
      })
    );
  };

  const handleAdvPayConfirm = () => {
    dispatch(
      updateJob({ id: jobId, job_status: 'presale', schedule_publish_at_date: null, schedule_publish_at_time: null }, () => {
        setShowAdvPayConfirm(false);
      })
    );
  };

  const handlePublishLaterCancel = () => {
    dispatch(
      updateJob({ id: jobId, schedule_publish_at_date: null, schedule_publish_at_time: null }, () => {
        setShowPublishLaterModal(false);
        setPublishLaterInfo({ date: null, time: null });
      })
    );
  };
  const handlePublishLaterSchedule = () => {
    dispatch(
      updateJob(
        {
          id: jobId,
          schedule_publish_at_date: publishLaterInfo.date,
          schedule_publish_at_time: publishLaterInfo.time
        },
        () => {
          setShowPublishLaterModal(false);

          if (showPublishConfirm) setShowPublishConfirm(false);
        }
      )
    );
  };
  const handlePublishLaterModalToggle = () => setShowPublishLaterModal(!showPublishLaterModal);

  const handleStatusModalToggle = () => {
    setMobileStatusIsOpen(!mobileStatusIsOpen);
  };

  const handleLookupConfigure = () => {
    setShowLookupWarningModal(false);
    history?.push(`/jobs/${jobId}/people`);
  };

  const smsTable = (title) => {
    return (
      <table className="table table--small mb-10">
        <thead className="table__header">
          <tr>
            <th>{title}…</th>
            <th></th>
          </tr>
        </thead>
        <tbody className="table__body">
          <tr>
            <td>
              Between 9 AM – 8 PM{' '}
              <span role="img" aria-label="sun">
                ☀️
              </span>
            </td>
            <td>Text Series starts immediately</td>
          </tr>
          <tr>
            <td>
              Between 8:01 PM – 8:59 AM{' '}
              <span role="img" aria-label="moon">
                🌒
              </span>
            </td>
            <td>Text Series starts at 9 AM</td>
          </tr>
        </tbody>
      </table>
    );
  };

  useEffect(() => {
    if (jobId && (!job.id || job.id !== jobId)) {
      dispatch(
        resetJob(() => {
          dispatch(
            getJob({ id: jobId }, ({ status, data }) => {
              // Check if Job is ARCHIVED or DELETED first
              if (status !== 200) {
                history?.push(`/jobs/${jobId}/notfound/deleted`);
              } else if (data?.job_status === 'archived') {
                history?.push(`/jobs/${jobId}/notfound/archived`);
              }
            })
          );
        })
      );
    }

    if (!timezones.length) {
      dispatch(getJobTimezoneList());
    }

    if (!smsTimes.length) {
      dispatch(getJobSmsTimeList());
    }
  }, []);

  useEffect(() => {
    if (job?.schedule_publish_at_date) {
      setPublishLaterInfo({
        date: moment(job.schedule_publish_at_date).isValid() ? moment(job.schedule_publish_at_date).toDate() : null,
        time: job.schedule_publish_at_time
      });
    }
  }, [job?.id]);

  useEffect(() => {
    // Dealing with the code lookup config error on job publish
    if (errors?.length) {
      const isCodeLookupError = errors.some((errorItem) => {
        if (errorItem?.code === 422 && errorItem.body?.error_localized?.includes('code lookup')) {
          return true;
        }

        return false;
      });

      if (isCodeLookupError && !history.location?.pathname?.includes('people')) {
        setShowLookupWarningModal(true);
      }
    }
  }, [errors]);

  const isPrivateGallery = ALLOW_EMAIL_CAMPAIGNS && job.access_mode === 'access_per_subject';
  const bulkExpired = job.job_status === 'onsale' && job.shipping_type.includes('bulk') && job.bulk_ship && moment().diff(job.bulk_ship.expires_at) <= 0;
  const bulkMissingInfo =
    (job.job_status === 'draft' || job.job_status === 'presale') &&
    job.price_sheet_shipping_type === 'drop_or_bulk_shipping' &&
    ((job.bulk_ship && hasEmptyInfoBulkShip(job.bulk_ship)) || job.bulk_ship === null);

  return (
    <>
      <header className="job-header">
        <div className="box flex middle">
          <div className="flex-3 flex-12-md job__mobile-header">
            <h1 className="text--truncate text--nomargin job-header__title">{job.name}</h1>
            <div className="job-header__title-container">
              <h3 className="text--truncate text--nomargin job-header__title--mobile">{job.name}</h3>
              <small className="text--capitalize" onClick={handleStatusModalToggle}>
                <span className="text--bold">Job Status: </span>
                {job.job_status}
              </small>
            </div>

            <button className="button button--outline text--truncate job-header__mobile-action" onClick={handleToggleNavigation}>
              <i className={currentItem.icon} />
              {currentItem.name}
            </button>
          </div>

          <div className="flex flex-9 between">
            <Navigation items={items} isOpen={navigationIsOpen} onToggleNavigation={handleToggleNavigation} />

            <aside className="flex end middle button-group job-header__actions hidden--md">
              <Tooltip title="Job History" position="bottom" arrow="true">
                <button className="button button--outline button--small" onClick={() => setShowJobHistory(true)}>
                  <i className="icon-time"></i>
                </button>
              </Tooltip>
              <Dropdown
                buttonName={
                  job.schedule_publish_at_date
                    ? 'Scheduled'
                    : { draft: 'Draft', expired: 'Expired', onsale: 'Published', presale: 'AdvancePay' }[job.job_status]
                }
                buttonExtraClass={'button--lean button-dropdown button--medium text--bold'}
              >
                <ul className="panel panel-dropdown panel-dropdown--large">
                  <li
                    className={`flex flex-row start panel-dropdown__item ${
                      job.job_status === 'draft' && job.schedule_publish_at_date === null ? 'hidden' : ''
                    }`}
                    onClick={() => handleJobStatusSelect('draft')}
                  >
                    <figure className="flex flex-2 middle center">
                      <img src={imageStoreDraft} alt="Set job to draft" width="48" />
                    </figure>
                    <p className="flex-9">
                      <b>Draft</b>
                      <br />
                      <small>Customers will no longer be able to view photos or buy products.</small>
                    </p>
                  </li>
                  <li
                    className={`flex flex-row start panel-dropdown__item ${
                      job.job_status === 'presale' && job.schedule_publish_at_date === null ? 'hidden' : ''
                    }`}
                    onClick={() => handleJobStatusSelect('presale')}
                  >
                    <figure className="flex flex-2 middle center">
                      <img src={imageCart} alt="Set job to AdvancePay" width="50" />
                    </figure>
                    <p className="flex-9">
                      <b>Set job to AdvancePay</b>
                      <br />
                      {/* Set up Access Mode first */}
                      {job.access_mode === null ? (
                        <small>
                          You must <span className="text--link">set up Gallery Access</span> first.
                        </small>
                      ) : (
                        <>
                          {/* Set up AdvancePay Credit first */}
                          {job.gift_card_sheet_id === null ? (
                            <small>
                              You must <span className="text--link">set up AdvancePay</span> first.
                            </small>
                          ) : (
                            <>
                              {/* Upload subjects data */}
                              {job.access_mode === 'access_per_subject' && job.setup_steps && job.setup_steps.uploaded_subjects === false ? (
                                <small>
                                  <span className="text--link">Upload subjects data</span> to set this status.
                                </small>
                              ) : (
                                <small>Give customers a way to purchase credits and preview products.</small>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </p>
                  </li>
                  <li
                    className={`flex flex-row panel-dropdown__item ${job.job_status === 'onsale' ? 'hidden' : ''}`}
                    onClick={() => handleJobStatusSelect('onsale')}
                  >
                    <figure className="flex flex-2 middle center">
                      <img src={imageStore} alt="Publish job" width="42" />
                    </figure>
                    <p className="flex-9">
                      <b>Publish Now</b>
                      <br />
                      {/* Set up Access Mode first */}
                      {job.access_mode === null ? (
                        <small>
                          You must <span className="text--link">set up Gallery Access</span> first.
                        </small>
                      ) : (
                        <>
                          {/* Set up PriceSheet first */}
                          {!job.price_sheet_id ? (
                            <small>
                              You must <span className="text--link">set up a price sheet</span> first.
                            </small>
                          ) : (
                            <>
                              {/* Upload subjects data */}
                              {job.access_mode === 'access_per_subject' && job.setup_steps && job.setup_steps.uploaded_subjects === false ? (
                                <small>
                                  <span className="text--link">Upload subjects data</span> to publish this job.
                                </small>
                              ) : (
                                <>
                                  {/* Add photo */}
                                  {job.setup_steps &&
                                  job.setup_steps.gallery_access === true &&
                                  job.setup_steps.storefront === true &&
                                  job.setup_steps.uploaded_photos === false &&
                                  photos.list.length === 0 ? (
                                    <small>
                                      You must add <span className="text--link">at least one photo</span>
                                    </small>
                                  ) : (
                                    <small>Customers can begin to view photos and buy products.</small>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </p>
                  </li>
                  <li
                    className={`flex flex-row panel-dropdown__item ${job.job_status === 'onsale' ? 'hidden' : ''}`}
                    onClick={() => handleJobStatusSelect('scheduled')}
                  >
                    <figure className="flex flex-2 middle center">
                      <img src={imageStoreTime} alt="Publish later" width="45" />
                    </figure>
                    <div className="flex-9">
                      <b>Publish Later</b>
                      <br />
                      {/* Set up Access Mode first */}
                      {job.access_mode === null ? (
                        <small>
                          You must <span className="text--link">set up Gallery Access</span> first.
                        </small>
                      ) : (
                        <>
                          {!job.price_sheet_id ? (
                            <small>
                              You must <span className="text--link">set up a price sheet</span> first.
                            </small>
                          ) : (
                            <>
                              {job.access_mode === 'access_per_subject' && job.setup_steps && job.setup_steps.uploaded_subjects === false ? (
                                <small>
                                  <span className="text--link">Upload subjects data</span> to schedule your job to be published.
                                </small>
                              ) : (
                                <>
                                  {job.setup_steps &&
                                  job.setup_steps.gallery_access === true &&
                                  job.setup_steps.storefront === true &&
                                  job.setup_steps.uploaded_photos === false &&
                                  photos.list.length === 0 ? (
                                    <small>
                                      You must add <span className="text--link">at least one photo</span>
                                    </small>
                                  ) : (
                                    <>
                                      {job.schedule_publish_at_date ? (
                                        <div className="flex between">
                                          <small>Scheduled.</small>
                                          <button className="button button--lean" name="edit" type="button" onClick={handlePublishLaterModalToggle}>
                                            Edit
                                          </button>
                                        </div>
                                      ) : (
                                        <small>Schedule your job to be published at a later date and time.</small>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </li>
                </ul>
              </Dropdown>
            </aside>
          </div>
        </div>
      </header>

      {/* Mobile job_status change modal */}
      <aside className={`modal job-status__modal ${mobileStatusIsOpen ? '' : 'transparent'}`}>
        <div className="modal__box">
          <header className="modal__header">
            <button className="button button--action modal__close" name="close" type="button" onClick={handleStatusModalToggle}>
              <i className="icon-close"></i>
            </button>
            <h3>Job Status</h3>
          </header>
          <main className="modal__content">
            <Dropdown
              buttonName={{ draft: 'Draft', expired: 'Expired', onsale: 'Published', presale: 'AdvancePay' }[job.job_status]}
              buttonExtraClass={'button--lean button-dropdown button--block text--bold'}
            >
              <ul className="panel panel-dropdown">
                <li
                  className={`flex flex-row start panel-dropdown__item ${job.job_status === 'draft' ? 'hidden' : ''}`}
                  onClick={() => handleJobStatusSelect('draft')}
                >
                  <figure className="flex flex-2 middle center">
                    <img src={imageStoreDraft} alt="Set job to draft" width="48" />
                  </figure>
                  <p className="flex-9">
                    <b>Draft</b>
                    <br />
                    <small>Customers will no longer be able to view photos or buy products.</small>
                  </p>
                </li>
                <li
                  className={`flex flex-row start panel-dropdown__item ${job.job_status === 'presale' ? 'hidden' : ''}`}
                  onClick={() => handleJobStatusSelect('presale')}
                >
                  <figure className="flex flex-2 middle center">
                    <img src={imageCart} alt="Set job to AdvancePay" width="50" />
                  </figure>
                  <p className="flex-9">
                    <b>Set job to AdvancePay</b>
                    <br />
                    {!job.gift_card_sheet_id ? (
                      <small>
                        You must <span className="text--link">set up AdvancePay</span> first.
                      </small>
                    ) : (
                      <>
                        {job.access_mode === 'access_per_subject' && job.setup_steps && job.setup_steps.uploaded_subjects === false ? (
                          <small>
                            <span className="text--link">Upload subjects data</span> to set this status.
                          </small>
                        ) : (
                          <small>Give customers a way to purchase credits and preview products.</small>
                        )}
                      </>
                    )}
                  </p>
                </li>
                <li
                  className={`flex flex-row panel-dropdown__item ${job.job_status === 'onsale' ? 'hidden' : ''}`}
                  onClick={() => handleJobStatusSelect('onsale')}
                >
                  <figure className="flex flex-2 middle center">
                    <img src={imageStore} alt="Publish job" width="42" />
                  </figure>
                  <p className="flex-9">
                    <b>Publish job</b>
                    <br />
                    {!job.price_sheet_id ? (
                      <small>
                        You must <span className="text--link">set up a price sheet</span> first.
                      </small>
                    ) : (
                      <>
                        {job.access_mode === 'access_per_subject' && job.setup_steps && job.setup_steps.uploaded_subjects === false ? (
                          <small>
                            <span className="text--link">Upload subjects data</span> to publish this job.
                          </small>
                        ) : (
                          <>
                            {job.setup_steps &&
                            job.setup_steps.gallery_access === true &&
                            job.setup_steps.storefront === true &&
                            job.setup_steps.uploaded_photos === false &&
                            photos.list.length === 0 ? (
                              <small>
                                You must add <span className="text--link">at least one photo</span>
                              </small>
                            ) : (
                              <small>Customers can begin to view photos and buy products.</small>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </p>
                </li>
                <li
                  className={`flex flex-row panel-dropdown__item ${job.job_status === 'onsale' ? 'hidden' : ''}`}
                  onClick={() => handleJobStatusSelect('scheduled')}
                >
                  <figure className="flex flex-2 middle center">
                    <img src={imageStoreTime} alt="Publish later" width="45" />
                  </figure>
                  <div className="flex-9">
                    <b>Publish Later</b>
                    <br />
                    {!job.price_sheet_id ? (
                      <small>
                        You must <span className="text--link">set up a price sheet</span> first.
                      </small>
                    ) : (
                      <>
                        {job.access_mode === 'access_per_subject' && job.setup_steps && job.setup_steps.uploaded_subjects === false ? (
                          <small>
                            <span className="text--link">Upload subjects data</span> to schedule your job to be published.
                          </small>
                        ) : (
                          <>
                            {job.setup_steps &&
                            job.setup_steps.gallery_access === true &&
                            job.setup_steps.storefront === true &&
                            job.setup_steps.uploaded_photos === false &&
                            photos.list.length === 0 ? (
                              <small>
                                You must add <span className="text--link">at least one photo</span>
                              </small>
                            ) : (
                              <>
                                {job.schedule_publish_at_date ? (
                                  <div className="flex between">
                                    <small>Scheduled.</small>
                                    <button className="button button--lean" name="edit" type="button" onClick={handlePublishLaterModalToggle}>
                                      Edit
                                    </button>
                                  </div>
                                ) : (
                                  <small>Schedule your job to be published at a later date and time.</small>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </li>
              </ul>
            </Dropdown>
          </main>
        </div>
      </aside>

      {/* Advance Pay Confirm Modal */}
      <aside className={`modal ${showAdvPayConfirm ? '' : 'transparent'}`}>
        <div className="modal__box modal__box--xlarge">
          <main className="flex gap-20 nowrap modal__content">
            <figure className="flex-4 hidden--md">
              <img src={imageAdvPay} alt="mobile device" />
            </figure>
            <section className="flex column nowrap between flex-8 flex-12-md">
              <article className="text--left">
                <h2>Hold up! Are you ready?</h2>
                {isPrivateGallery ? (
                  <p>
                    When you set this job to AdvancePay (AP), PhotoDay kicks off a series of text messages to customers subscribed to the gallery. These text
                    and email campaigns* are carefully crafted, timed, and designed to keep your customers engaged and excited to purchase AP credits!
                  </p>
                ) : (
                  <p>
                    When you set this job to AdvancePay (AP), PhotoDay kicks off a series of text messages to customers subscribed to the gallery. Text messages
                    are carefully crafted, timed, and designed to keep your customers engaged and excited to purchase AP credits!
                  </p>
                )}
                <p>
                  Once the job is set to AP, the text series starts immediately—except between the hours of 8 PM and 8:59 AM, in which case the series of text
                  messages will start at 9 AM (based on this job’s timezone).
                </p>
                {smsTable('When setting a job to AdvancePay (AP)')}
                {isPrivateGallery && (
                  <p className="text--italic">
                    <small>
                      * The email campaign is toggled off by default, but if you’d like to activate it, first add subject data to the job, then go to{' '}
                      <b>
                        Job {'>'} Settings {'>'} Campaigns
                      </b>{' '}
                      to switch it on before proceeding. Emails will send immediately.
                    </small>
                  </p>
                )}

                {/* Helpers */}
                <aside className="panel panel--secondary panel--neat">
                  <ul className="list--bullet text--small">
                    <li>
                      <a href="https://support.photoday.io/en/articles/3241234-what-are-gallery-statuses" target="_blank" rel="noopener noreferrer">
                        What are Job Statuses?
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://support.photoday.io/en/articles/5614221-how-do-i-communicate-with-customers-about-picture-day#h_74a9e47640"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Learn more
                      </a>{' '}
                      about PhotoDay’s text messaging (SMS) feature.
                    </li>
                    <li>
                      Check out the automatic AdvancePay{' '}
                      <a href="https://support.photoday.io/en/articles/5998549-sms-campaign-pre-picture-day-series" target="_blank" rel="noopener noreferrer">
                        SMS
                      </a>{' '}
                      and{' '}
                      <a href="https://support.photoday.io/en/articles/5976248-email-campaign-pre-picture-day-series" target="_blank" rel="noopener noreferrer">
                        Email
                      </a>{' '}
                      campaigns.
                    </li>
                  </ul>
                </aside>
              </article>
              <footer className="flex gap-10 center">
                <button className="button button--large" name="advancepay" type="button" onClick={handleAdvPayConfirm}>
                  Set to AdvancePay
                </button>
                <button className="button button--outline button--large" name="ready" type="button" onClick={handleAdvPayConfirmToggle}>
                  I'm not ready yet
                </button>
              </footer>
            </section>
          </main>
        </div>
      </aside>

      {/* Publish confirm modal */}
      <aside className={`modal ${showPublishConfirm ? '' : 'transparent'}`}>
        <div className="modal__box modal__box--xlarge">
          <main className="flex gap-20 nowrap modal__content">
            <figure className="flex-4 hidden--md">
              <img src={imageGalleryDevices} alt="mobile devices" />
            </figure>
            <section className="flex column nowrap between flex-8 flex-12-md">
              <article className="text--left">
                <h2>Hold up! Before you publish...</h2>
                {isPrivateGallery ? (
                  <p>
                    When you publish a job, PhotoDay kicks off a series of texts and emails* to customers subscribed to the gallery. These campaigns are
                    carefully crafted, timed, and designed to keep your customers engaged and excited to order photo products.
                  </p>
                ) : (
                  <p>
                    When you publish a job, PhotoDay kicks off a series of text messages to customers subscribed to the gallery. These texts are carefully
                    crafted, timed, and designed to keep your customers engaged and excited to order photo products.
                  </p>
                )}
                <p>
                  Once the job is published, the text series starts immediately—except between the hours of 8 PM and 8:59 AM, in which case the series of text
                  messages will start at 9 AM (based on this job’s timezone).
                </p>
                {smsTable('When setting a job')}
                {isPrivateGallery && (
                  <p className="text--italic">
                    <small>
                      * The email campaign is toggled off by default, but if you’d like to activate it, first add subject data to the job, then go to{' '}
                      <b>
                        Job {'>'} Settings {'>'} Campaigns
                      </b>{' '}
                      to switch it on before proceeding. Emails will send immediately.
                    </small>
                  </p>
                )}
                {/* Helpers */}
                <aside className="panel panel--secondary panel--neat">
                  <ul className="list--bullet text--small">
                    <li>Want to see how your gallery looks without publishing? We suggest creating a test job!</li>
                    <li>
                      Need to schedule your gallery to publish at a certain time? Try the{' '}
                      <span className="text--nowrap">
                        “
                        <button className="button--link" onClick={() => handleJobStatusSelect('scheduled')}>
                          Publish Later
                        </button>
                        ”
                      </span>{' '}
                      option.
                    </li>
                    <li>
                      <a
                        href="https://support.photoday.io/en/articles/5614221-how-do-i-communicate-with-customers-about-picture-day#h_74a9e47640"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Learn more
                      </a>{' '}
                      about PhotoDay’s text messaging (SMS) feature.
                    </li>
                    <li>
                      Check out the automatic Post-Picture Day{' '}
                      <a href="https://support.photoday.io/en/articles/6001290-sms-campaign-post-picture-day-series" target="_blank" rel="noopener noreferrer">
                        SMS
                      </a>{' '}
                      {isPrivateGallery && (
                        <>
                          and{' '}
                          <a
                            href="https://support.photoday.io/en/articles/5976254-email-campaign-post-picture-day-series"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Email
                          </a>{' '}
                        </>
                      )}
                      campaign{isPrivateGallery ? 's' : ''}.
                    </li>
                  </ul>
                </aside>
              </article>
              <footer>
                {/* Warnings */}
                <aside className="panel panel--secondary panel--neat text--left">
                  <img className="mb-10" src={imageWarning} alt="Warning" width="14" height="14" />
                  <ul className="list--bullet mb-20">
                    <li>
                      Be sure to publish your job only when you're 100% ready for your customers to view the photos. When you hit publish, the first messages
                      will be sent immediately or scheduled for 9 AM, and the series cannot be restarted.
                    </li>
                    {job?.biometrics_enabled && job?.photo_stats?.photos_trained < job?.photo_stats?.photos && (
                      <li className="mt-10">
                        {isPrivateGallery ? (
                          <>
                            Images are still running through the FaceFind process. Publishing the job prior to completion and subject match verification is
                            strongly discouraged. It could cause some customers to receive a communication indicating their child has no images available.
                          </>
                        ) : (
                          <>
                            Images are still running through the FaceFind process. Publishing the job prior to completion is discouraged. It could temporarily
                            cause some customer FaceFind searches to return no results.
                          </>
                        )}
                      </li>
                    )}
                    {(bulkExpired === true || bulkMissingInfo === true) && (
                      <li className="mt-10">
                        <b>Hold-up!</b> It looks like this job is using a Bulk or Drop Price Sheet, but there are no bulk shipping details added. If you want to
                        proceed with publishing, only drop shipping will be available to your customers. Are you sure you want to publish?
                      </li>
                    )}
                    {job?.advancepay_offer?.expired === true && (
                      <li className="mt-10">
                        Your AdvancePay offer {job.advancepay_offer?.name} expired on {shortDate(job.advancepay_offer?.expiration)}. If you publish without
                        updating the expiration date, the offer will not work for customers who purchased an AdvancePay. Would you like to publish it still?
                      </li>
                    )}
                  </ul>
                  <div className="flex gap-10 center">
                    <button className="button button--large" name="publish" type="button" onClick={handlePublishConfirm}>
                      Acknowledge & Publish!
                    </button>
                    <button className="button button--outline button--large" name="ready" type="button" onClick={handlePublishConfirmToggle}>
                      I'm not ready yet
                    </button>
                  </div>
                </aside>
              </footer>
            </section>
          </main>
        </div>
      </aside>

      {/* Publish Later modal */}
      <aside className={`modal ${showPublishLaterModal ? '' : 'transparent'}`}>
        <div className="modal__box modal__box--dark modal__box--xsmall modal__box--overflow text--left">
          <header className="modal__header">
            <button className="button button--action modal__close" name="close" type="button" onClick={handlePublishLaterModalToggle}>
              <i className="icon-close"></i>
            </button>
            <h3>Publish Later</h3>
          </header>
          <main className="modal__content">
            <fieldset>
              <DatePicker
                className="input--date mb-10"
                name="date"
                strictParsing
                isClearable={true}
                autoComplete="off"
                dateFormat="MM/dd/yy"
                minDate={new Date()}
                placeholderText="Publish Date (MM/DD/YY)"
                selected={publishLaterInfo.date}
                onChange={(date) => setPublishLaterInfo({ ...publishLaterInfo, date })}
              />
              <Select
                className="select"
                classNamePrefix="select"
                name="time"
                placeholder="Publish Time"
                isDisabled={!publishLaterInfo.date}
                options={
                  moment(publishLaterInfo.date).isSame(new Date(), 'day')
                    ? // Filter so that user don't see past hours on options
                      smsTimes
                        .filter((time) => {
                          if (jobTimezone) {
                            const smsTime = moment(time, 'HH:mm a');
                            const now = moment.tz(jobTimezone);
                            return (
                              smsTime.get('hours') > now.get('hours') ||
                              (smsTime.get('hours') === now.get('hours') && smsTime.get('minutes') > now.get('minutes'))
                            );
                          }
                          return true;
                        })
                        .map((time) => ({ value: time, label: time }))
                    : smsTimes.map((time) => ({ value: time, label: time }))
                }
                value={publishLaterInfo.time ? smsTimes.filter((time) => time === publishLaterInfo.time).map((time) => ({ value: time, label: time })) : null}
                onChange={(select) => setPublishLaterInfo({ ...publishLaterInfo, time: select.value })}
              />
            </fieldset>
            <p className="text--small">
              Note the current time zone set for this job is <b>{job.time_zone}</b>. To change this{' '}
              <Link to={`/jobs/${jobId}/settings/details`}>click here</Link>.
            </p>
          </main>
          <footer className="modal__footer text--center">
            <aside className="button-group">
              {job.schedule_publish_at_date && (
                <button className="button button--outline button--medium" name="button" type="button" onClick={handlePublishLaterCancel}>
                  Cancel Schedule
                </button>
              )}
              <button
                className="button button--medium"
                name="button"
                type="button"
                onClick={handlePublishLaterSchedule}
                disabled={requesting || !publishLaterInfo.date || !publishLaterInfo.time}
              >
                {job.schedule_publish_at_date ? 'Re-schedule' : 'Schedule'}
              </button>
            </aside>
            <hr />
            <p>
              <small>
                Need help? Check out our{' '}
                <a href="https://support.photoday.io/en/articles/3733626-when-is-the-best-time-to-publish-a-gallery" target="_blank" rel="noopener noreferrer">
                  support article on scheduling a job
                </a>
                .
              </small>
            </p>
          </footer>
        </div>
      </aside>

      {/* Job history modal */}
      {showJobHistory && (
        <aside className="modal animate">
          <div className="modal__box">
            <header className="modal__header">
              <button className="button button--action modal__close" name="close" type="button" onClick={() => setShowJobHistory(false)}>
                <i className="icon-close"></i>
              </button>
              <h3 className="text--left">Job History</h3>
            </header>
            <main className="modal__content">
              <table className="table table--lean">
                <thead className="table__header">
                  <tr>
                    <th>Date and Time</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody className="table__body">
                  {job.activity_feed &&
                    job.activity_feed.map((item) => (
                      <tr key={item.activity.id}>
                        <td data-header="Date and Time" className="text--nowrap">
                          {item.activity?.created_at
                            ? `${moment(item.activity.created_at).tz(jobTimezone).format('L [@]h:mm A')} ${moment.tz(jobTimezone).zoneAbbr()}`
                            : 'N/A'}
                        </td>
                        <td data-header="Description">{item.message}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </main>
          </div>
        </aside>
      )}

      {/* Bulk or Drop PriceSheet modal */}
      <aside className={`modal ${showBulkDropConfirm ? '' : 'transparent'}`}>
        <div className="modal__box modal__box--xsmall modal__box--nomin text--left">
          <header className="modal__header">
            <h3 className="text--gold">Hold Up!</h3>
          </header>
          <main className="modal__content">
            <p>
              It looks like this job is using a Bulk or Drop Price Sheet, but there are no bulk shipping details added. If you want to proceed with publishing,
              only drop shipping will be available to your customers. Are you sure you want to publish?
            </p>
          </main>
          <footer className="modal__footer flex end gap-10">
            <button className="button" name="publish" type="button" onClick={handlePublishConfirm}>
              Publish
            </button>
            <button className="button button--outline" name="cancel" type="button" onClick={handleBulkDropConfirmToggle}>
              Cancel
            </button>
          </footer>
        </div>
      </aside>

      {/* Lookup Configuration Error */}
      {showLookupWarningModal && (
        <aside className="modal animate">
          <div className="modal__box modal__box--small">
            <header>
              <h2>Mapping Error - Can't Publish Job</h2>
            </header>
            <main className="modal__content">
              <p>
                Access ID Code Lookup will not work due to one or more of your code lookup fields not matching the data being mapped. You can either adjust the
                lookup fields to match the data or disable the code look up and publish your job.
              </p>
            </main>
            <footer className="modal__footer modal__footer--fixed end">
              <button className="button" type="button" name="configure" onClick={handleLookupConfigure}>
                Configure
              </button>
            </footer>
          </div>
        </aside>
      )}

      {/* Business Verification Banner */}
      <div className="box">
        <BusinessVerificationBanner />
      </div>

      {/* Stripe Express Migration Banner */}
      <div className="box">
        <StripeExpressMigrationBanner />
      </div>
    </>
  );
});

Header.propTypes = {
  history: PropTypes.object.isRequired,
  jobId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default Header;
