import { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, NavLink, Redirect } from 'react-router-dom';

// Components
import Labs from './Labs';
import Payment from './Payment';
import StudioUsers from './Users';
import StudioTaxes from './Taxes';
import StudioWallet from './Wallet';
import StudioInfo from './Information';
import StudioVerification from './Verification';
import StudioWatermark from './Watermark';
import PrivateRoute from '@/components/Shared/PrivateRoute';

// Styles
import './studio.css';
import './../styles.css';

const SERVICES_ENABLED = import.meta.env.VITE_SERVICES_ENABLED === '1';

const mapStateToProps = (state) => {
  const {
    login: { user },
    users: { studio }
  } = state;

  return { user, studio };
};

class MyStudio extends Component {
  state = {
    section: 'Studio Information',
    sidebarIsOpen: false
  };

  componentDidMount() {
    this.setSectionTitle(this.props.location.pathname);
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setSectionTitle(this.props.location.pathname);
    }
  };

  setSectionTitle = (path) => {
    const pathArr = path.split('/');
    const section = pathArr[pathArr.length - 1];

    if (section === 'my-studio' && this.state.section !== 'Studio Information') {
      this.setState({ section: 'Studio Information' });
    }

    if (section === 'verification' && this.state.section !== 'Verification') {
      this.setState({ section: 'Verification' });
    }

    if (section === 'labs' && this.state.section !== 'Labs') {
      this.setState({ section: 'Labs' });
    }

    if (section === 'payment' && this.state.section !== 'Payment') {
      this.setState({ section: 'Payment' });
    }

    if (section === 'taxes' && this.state.section !== 'Taxes') {
      this.setState({ section: 'Taxes' });
    }

    if (section === 'watermark' && this.state.section !== 'Logo and Watermark') {
      this.setState({ section: 'Logo and Watermark' });
    }

    if (section === 'credits' && this.state.section !== 'Credits') {
      this.setState({ section: 'Credits' });
    }

    if (section === 'users' && this.state.section !== 'Users') {
      this.setState({ section: 'Users' });
    }
  };

  handleToggleSidebar = () => {
    this.setState({ sidebarIsOpen: !this.state.sidebarIsOpen });
  };

  render() {
    const { user, studio } = this.props;
    const isStudioVerificationRequired = studio?.verification_required;
    const roles = (user && user.roles) || [];

    return (
      <>
        <aside className="flex-3 flex-12-md sidebar-container">
          <header className="flex middle between sidebar-header">
            <h2 className="text--nomargin">My Studio</h2>
            <button className="sidebar__mobile-action button button--outline" onClick={this.handleToggleSidebar}>
              {this.state.section}
            </button>
          </header>

          <nav className={`sidebar-navigation ${this.state.sidebarIsOpen ? 'sidebar-navigation--open' : ''}`} onClick={this.handleToggleSidebar}>
            {roles.includes('manage_studio_settings') && (
              <>
                <NavLink className="sidebar-navigation__item" activeClassName="sidebar-navigation__item--active" to={`/user/my-studio/information`}>
                  Studio Information
                </NavLink>
                {isStudioVerificationRequired && (
                  <NavLink className="sidebar-navigation__item" activeClassName="sidebar-navigation__item--active" to={`/user/my-studio/verification`}>
                    Verification
                  </NavLink>
                )}
                <NavLink className="sidebar-navigation__item" activeClassName="sidebar-navigation__item--active" to={`/user/my-studio/labs`}>
                  Labs
                </NavLink>
              </>
            )}
            {roles.includes('manage_stripe') && (
              <NavLink className="sidebar-navigation__item" activeClassName="sidebar-navigation__item--active" to={`/user/my-studio/payment`}>
                Payment
              </NavLink>
            )}
            {roles.includes('manage_studio_settings') && (
              <>
                <NavLink className="sidebar-navigation__item" activeClassName="sidebar-navigation__item--active" to={`/user/my-studio/taxes`}>
                  Taxes
                </NavLink>
                <NavLink className="sidebar-navigation__item" activeClassName="sidebar-navigation__item--active" to={`/user/my-studio/watermark`}>
                  Logo and Watermark
                </NavLink>
              </>
            )}
            {SERVICES_ENABLED && (
              <NavLink className="sidebar-navigation__item" activeClassName="sidebar-navigation__item--active" to={`/user/my-studio/wallet`}>
                Wallet
              </NavLink>
            )}
            {roles.includes('manage_users') && (
              <NavLink className="sidebar-navigation__item" activeClassName="sidebar-navigation__item--active" to={`/user/my-studio/users`}>
                Users
              </NavLink>
            )}
          </nav>
        </aside>

        <section className="flex-9 flex-12-md my-studio__section">
          <Switch>
            {roles.includes('manage_studio_settings') ? (
              <Redirect exact from="/user/my-studio" to="/user/my-studio/information" />
            ) : (
              <Redirect exact from="/user/my-studio" to="/user/my-studio/payment" />
            )}
            <PrivateRoute path="/user/my-studio/information" component={StudioInfo} requiredPermission={'manage_studio_settings'} />
            <PrivateRoute path="/user/my-studio/verification" component={StudioVerification} requiredPermission={'manage_studio_settings'} />
            <PrivateRoute path="/user/my-studio/labs" component={Labs} requiredPermission={'manage_studio_settings'} />
            <PrivateRoute path="/user/my-studio/payment" component={Payment} requiredPermission={'manage_stripe'} />
            <PrivateRoute path="/user/my-studio/taxes" component={StudioTaxes} requiredPermission={'manage_studio_settings'} />
            <PrivateRoute path="/user/my-studio/watermark" component={StudioWatermark} requiredPermission={'manage_studio_settings'} />
            <PrivateRoute path="/user/my-studio/wallet" component={StudioWallet} />
            <PrivateRoute path="/user/my-studio/users" component={StudioUsers} requiredPermission={'manage_users'} />
          </Switch>
        </section>
      </>
    );
  }
}

export default connect(mapStateToProps)(MyStudio);
